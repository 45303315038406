.section-heading {
    position: relative;
    z-index: 1;
    margin-bottom: 50px
}

.section-heading h2 {
    font-size: 30px;
    text-transform: capitalize;
    margin-bottom: 15px
}

.section-heading>p {
    margin-bottom: 0;
    line-height: 2;
    font-size: 16px;
    max-width: 600px;
    margin: 0 auto
}

.subscribe .section-heading>p {
    color: #eee
}

.subscribe .section-heading {
    margin-bottom: 30px
}

.dream-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px
}

.dream-dots span {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 5px
}

.dream-dots span:first-child {
    background-color: #00a8ff
}

.dark.classy-nav-container {
    background-color: transparent
}

@media(min-width: 1200px) {
    .demo .container {
        max-width: 1280px
    }
}

.timeline-split {
    position: relative;
    width: 100%
}

.timeline-split {
    position: relative;
    width: 100%
}

.timeline-split .timeline {
    position: relative;
    padding: 50px;
    overflow: hidden
}

.timeline-split .timeline h3 {
    font-size: 20px;
    color: rgb(253, 193, 19)
}

.timeline-split .timeline span {
    color: #bb8c0d;
    display: block;
    /* margin-bottom: 10px; */
    text-align: left;
}

.timeline-split .timeline ul {
    margin-bottom: 0px !important;
}

.timeline-split .timeline::before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    background: rgb(253, 193, 19);
    left: calc(50% - 10px)
}

.timeline-split .timeline::after {
    content: "";
    width: 2px;
    height: 4000px;
    display: block;
    position: absolute;
    top: 10px;
    left: 50%;
    left: calc(50% - 1px);
    background: rgb(253, 193, 19);
}

.timeline-split .timeline .block {
    width: 50%;
    padding: 10px;
    background: #0d003b;
    width: calc(50% - 74px);
    text-align: left;
    position: relative;
    border: 1px solid rgb(253, 193, 19);
    border-radius: 5px;
}

.timeline .block .date {
    padding: 5px 10px;
    /* display: inline-block; */
    background: rgb(253, 193, 19);
    margin: 10px 0;
    color: #fff;
    font-size: 13px;
    border-radius: 15px;
    text-align: left;
}

.timeline .block .between {
    padding: 5px 10px;
    display: inline-block;
    color: #fff
}

.timeline-split .timeline .block::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    background: rgb(253, 193, 19);
    top: 30px
}

.timeline-split .timeline .block.block-left::after,
.timeline-split .timeline .block.block-right::after {
    content: "";
    width: 95px;
    height: 2px;
    position: absolute;
    background: rgb(253, 193, 19);
    top: 34px;
    z-index: 1;
    right: -78px
}

.timeline-split .timeline .block.block-right::after {
    left: -80px
}

.timeline-split .timeline .block.block-left {
    text-align: right
}

.timeline-split .timeline .block.block-left::before {
    right: -80px
}

.timeline .block p {
    margin-bottom: 0
}

.timeline-split .timeline .block.block-right {
    text-align: left;
    margin-left: 50%;
    margin-left: calc(50% + 74px)
}

.timeline-split .timeline .block.block-right::before {
    left: -80px
}

@media(max-width: 992px) {
    .timeline-split .timeline {
        padding: 50px 20px
    }

    .timeline-split .timeline h3 {
        font-size: 19px
    }
}

@media(max-width: 992px) {
    .timeline-split .timeline .block.block-left::after {
        left: -80px
    }

    .timeline-split .timeline::after {
        left: 9px
    }

    .timeline-split .timeline::before {
        left: 0
    }

    .timeline-split .timeline .circle {
        left: 2px
    }

    .timeline-split .timeline .block {
        width: 100% !important;
        text-align: left;
        padding-left: 20px
    }

    .timeline-split .timeline .block::before {
        left: -15px
    }

    .timeline-split .timeline .block.block-left {
        text-align: left
    }

    .timeline-split .timeline .block.block-right {
        margin-left: 0
    }

    .timeline-split .timeline .block.block-right::before {
        left: -15px
    }

    .mt-30 {
        margin-top: 30px
    }
}

.mt-30 {
    margin-top: 30px
}

.service_h6 {
    color: #fdc113;
}

.service_p {
    text-align: left;
    margin: 5px;
    padding: 10px;
    color: #fdc113;
    border: 1px solid #fff;
    border-radius: 5px;
    font-size: 16px;
    min-height: 175px;
}